import React from 'react';

import {
  Segment,
  Header,
  Container,
  List,
  Card,
  Image,
  Label,
} from 'semantic-ui-react';

import {
  FaPython,
  FaReact,
  FaDocker,
  FaSwift,
  FaAppStoreIos,
  FaGithub,
  FaJenkins,
  FaAws,
  FaDigitalOcean,
  FaDiscord,
} from 'react-icons/fa';
import {
  SiKubernetes,
  SiGrafana,
  SiGithubactions,
  SiMysql,
  SiPostgresql,
  SiElastic,
  SiSplunk,
  SiPostman,
 } from 'react-icons/si';
 import {
   FcGoogle,
 } from 'react-icons/fc';


import thisWebPage from '../images/gh_pages.jpeg';
import profile from '../images/profile.jpg';
import khonorifics from '../images/khonorifics.png';
import cryptographygame from '../images/cryptographygame.png';
import enoughCream from '../images/enough-cream.png';

function Introduction () {
  return (
    <Segment className='viewport-fill vertical-center' basic padded='very' textAlign='left'>
      <Container text>
      <Image
        src={profile}
        size='small'
        rounded
        data-aos="fade-up"
        data-aos-duration='1000'
      />
        <Header style={{fontSize: "2.5em"}} data-aos="fade-up" data-aos-duration='1000' data-aos-delay='500'>It's me! Evan Lee.</Header>
        <List style={{fontSize: "1.25em"}}>
          <List.Item data-aos="fade-up" data-aos-duration='1000' data-aos-delay='1100'>
            <p>Software engineer since 2016. </p>
          </List.Item>
          <List.Item data-aos="fade-up" data-aos-duration='1000' data-aos-delay='1200'>
            <p>Completed a <u>Master of Engineering</u> in Computer Science at the <u>University of Colorado Boulder</u> in 2021.</p>
          </List.Item>
          <List.Item data-aos="fade-up" data-aos-duration='1000' data-aos-delay='1300'>
            <p>I usually use the handle <u><code>@Archetypically</code></u> or <u><code>@ArchetypicalLee</code></u>.</p>
          </List.Item>
        </List>
      </Container>
    </Segment>
  )
}

function Projects () {
  return (
    <Segment className='viewport-fill vertical-center' basic padded='very' textAlign='left'>
      <Container>
        <Header size='large' data-aos="fade-up"> Projects </Header>
        <Card.Group data-aos="fade-up">
          <Card color='orange' href="/">
            <Image src={thisWebPage} wrapped ui={false} />
            <Card.Content>
              <Card.Header>A study of recursive structures and programs</Card.Header>
              <Card.Meta>
                <i>September 2020</i>
              </Card.Meta>
              <Card.Description>
                Technical analysis and practical demo of recursion as deployed unknowningly on the public internet.
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <List horizontal>
                <List.Item content={ <FaReact /> } />
                <List.Item content={ <FaGithub /> } />
              </List>
            </Card.Content>
          </Card>
          <Card
            color='blue'
            href="https://khonorifics.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={khonorifics} wrapped ui={false} />
            <Card.Content>
              <Card.Header>KHonorifics.com</Card.Header>
              <Card.Meta>
                <i>September 2020</i>
              </Card.Meta>
              <Card.Description>
                A simple, single-page, at-a-glance tool used to compare K-pop hierarchical relationships.
                Full-stack development. Front-end written in React and deployed into GitHub Pages.
                Backend written in Python and deployed into Kubernetes in DigitalOcean.
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <List horizontal>
                <List.Item content={ <FaPython /> } />
                <List.Item content={ <FaReact /> } />
                <List.Item content={ <FaDocker /> } />
                <List.Item content={ <SiKubernetes /> } />
                <List.Item content={ <FaGithub /> } />
              </List>
            </Card.Content>
          </Card>
          <Card
            color='purple'
            href="https://cryptographyga.me/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={cryptographygame} wrapped ui={false} />
            <Card.Content>
              <Card.Header>cryptographyga.me</Card.Header>
              <Card.Meta>
                <i>May 2021</i>
              </Card.Meta>
              <Card.Description>
                "Which cryptography are you?"
                A final project for a graduate-level cybersecurity course.
                Written in React. deployed into GitHub Pages.
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <List horizontal>
                <List.Item content={ <FaPython /> } />
                <List.Item content={ <FaReact /> } />
                <List.Item content={ <FaGithub /> } />
              </List>
            </Card.Content>
          </Card>
          <Card
            color='green'
            href='https://github.com/Archetypically/python-boilerplate-logger'
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card.Content>
              <Card.Header>python-boilerplate-logger</Card.Header>
              <Card.Meta>
                <i>May 2020</i>
              </Card.Meta>
              <Card.Description>
                Let's get the 'got here' out of here.
                <br />
                <a
                  href='https://drive.google.com/file/d/1A_wwFvdMtNjq_4QMgri9wXH_0nVXeFFH/view?usp=sharing'
                  target="_blank"
                  rel="noopener noreferrer"
                >Read the paper!</a>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <List horizontal>
                <List.Item content={ <FaPython /> } />
              </List>
            </Card.Content>
          </Card>
          <Card
            color='purple'
            href='https://github.com/Archetypically/dayvid-bot'
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card.Content>
              <Card.Header>Dayvid Bot</Card.Header>
              <Card.Meta>
                <i>December 2018</i>
              </Card.Meta>
              <Card.Description>
                Discord bot to correct spellings of the name <code>David</code>.
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <List horizontal>
                <List.Item content={ <FaPython /> } />
                <List.Item content={ <FaDiscord /> } />
              </List>
            </Card.Content>
          </Card>
          <Card color='brown'>
            <Image src={enoughCream} wrapped ui={false} />
            <Card.Content>
              <Card.Header>Enough Cream</Card.Header>
              <Card.Meta>
                <i>On-hold</i>
              </Card.Meta>
              <Card.Description>
                An iOS app that allows you to detect if there is enough cream in your coffee.
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <List horizontal>
                <List.Item content={ <FaAppStoreIos /> } />
                <List.Item content={ <FaSwift /> } />
              </List>
            </Card.Content>
          </Card>
        </Card.Group>
      </Container>
    </Segment>
  )
}

function Skills () {
  return (
    <Segment className='viewport-fill vertical-center' basic padded='very' textAlign='left'>
      <Container>
        <Header size='large' data-aos="fade-up"> Skills </Header>
        <Label.Group size='huge' data-aos="fade-up">
          <Label color="blue">
            Python  <FaPython />
          </Label>
          <Label color="orange">
            REST  <SiPostman />
          </Label>
          <Label color="olive">
            CI/CD  <FaJenkins />  <SiGithubactions />
          </Label>
          <Label color="yellow">
            Cloud Computing  <FaAws />  <FcGoogle />  <FaDigitalOcean />
          </Label>
          <Label color="pink">
            DevOps  <SiGrafana />  <SiElastic />  <SiSplunk />
          </Label>
          <Label color="blue">
            SQL  <SiMysql />  <SiPostgresql />
          </Label>
        </Label.Group>
      </Container>
    </Segment>
  )
}

function Links () {
  return (
    <Segment className='viewport-fill vertical-center' basic padded='very' textAlign='left'>
      <Container>
        <Header size='large' data-aos="fade-up"> Referral Links </Header>
        <List animated size='large' relaxed divided data-aos="fade-up">
        <List.Item>
          <List.Content>
            <List.Header
              as='a'
              href='https://m.do.co/c/1f5d6fa22dbe'
              target='_blank'
              rel='noreferrer noopener'
            ><FaDigitalOcean /> DigitalOcean</List.Header>
            <List.Description>Get $100 in credit over 60 days on DigitalOcean!</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header
              as='a'
              href='https://refer.etrade.net/theevanclee'
              target='_blank'
              rel='noreferrer noopener'
            >
              E*TRADE
            </List.Header>
            <List.Description>Get up to $2,500 when opening a new account on E*TRADE!</List.Description>
          </List.Content>
        </List.Item>
        </List>
      </Container>
    </Segment>
  )
}

export default function About() {
  return (
    <div>
      <Introduction />
      <Projects />
      <Skills />
      <Links />
    </div>
  )
}
